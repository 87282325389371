<template>
  <div class="content">
    <sub-title>게시판</sub-title>
    <div class="board_list">
      <div class="hd">
        <span class="number">번호</span>
        <span class="title">제목</span>
        <span class="author">작성자</span>
        <span class="time">시간</span>
      </div>
      <div class="bd_board_content" v-html="item.content">
      </div>
      <div class="board_list" v-if="item.sportsBetInfoList">
        <sports-bet-history-details-for-free-board :bet-list="item.sportsBetInfoList"></sports-bet-history-details-for-free-board>
      </div>
      <div class="board_list" v-if="item.leisureGameBetInfoList">
        <sports-bet-history-details-for-free-board :bet-list="item.leisureGameBetInfoList"></sports-bet-history-details-for-free-board>
      </div>
      <div class="go_back">
        <button class="btn03" @click="goback()">리스트 보기</button>
      </div>

    </div>

  </div>

</template>


<script>

import {
  getBoardDetail,saveBoardComment,
} from "@/network/userRequest";
import {postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import Pagination from "@/components/pagenation/Pagination.vue";
import FreeBoardWriter from "@/views/afront/notice/FreeBoardWriter.vue";
import SportsBetHistoryDetailsForFreeBoard from "@/views/afront/sports/match/SportsBetHistoryDetailsForFreeBoard.vue";

export default {
  name: "FreeBoardDetail",
  mixins: [postionMixin],
  components: {SportsBetHistoryDetailsForFreeBoard, FreeBoardWriter, Pagination, SubTitle},
  data() {
    return {
      sportsConst,
      item: {user: {nickname: ''}, commentList: [], sportsBetInfoList: []},
      boardId: 0
    }
  },
  methods: {
    saveBoardComment() {
      saveBoardComment(this.boardComment).then(res => {
        if (this.boardComment.content.trim() === '') {
          this.$swal({
            title: '댓글 내용을 입력하세요',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
        if (res.data.success) {
          this.$swal({
            title: '댓글 작성이 완료되였습니다',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.content = ''
          this.title = ''
          this.$router.go(0)
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }

      })

    },
    commentSuccess() {
      this.getBoardDetail(this.boardId)
    },
    getBoardDetail() {
      getBoardDetail(this.boardId).then(res => {
        this.item = {}
        if (res.data.success) {
          this.item = res.data.data
        }
      })
    },
    goback() {
      this.$router.go(-1)
    },
  },
  created() {
    this.boardId = this.$route.query.id
    this.getBoardDetail()
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontnotice.css");


</style>